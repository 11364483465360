<template>
  <v-card
    class="mt-0 mb-0"
    elevation="1"
    height="150"
    :style="$vuetify.breakpoint.smAndDown ? 'border-radius: 8px; padding: 5px; display: flex; align-items: center;' : 'border-radius: 8px; padding: 10px; display: flex; align-items: center;'"
  >
    <v-row style="height: 150px; width: 100%;">
      <v-col
        cols="2"
        md="2"
        class=""
      >
        <div style="height: 100%; display: flex; flex-direction: column">
          <v-img
            contain
            max-height="130"
            max-width="500"
            :src="produto.imagens[0]"
          />
        </div>
      </v-col>
      <v-col
        cols="10"
        md="10"
        class=""
      >
        <v-row>
          <v-col
            cols="12"
            class=""
          >
            <div>
              <div
                :title="produto.nome"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 14px; color: #183B94;font-weight: 600; width: 100%; min-width: 30px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' : 'font-size: 18px; color: #183B94;font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'"
              >
                {{ produto.nome }}
              </div>
              <div
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 12px; font-weight: 600;' : 'font-size: 14px; font-weight: 600;'"
              >
                {{ produto.marca }}
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2 ? '7' : '12'"
                md="12"
                class="pt-1"
              >
                <div
                  v-if="produto.disponibilidade === 2"
                  :style="$vuetify.breakpoint.smAndUp ? 'font-size: 14px; font-weight: 600; color: #ff8f00; height: 25px;' : 'font-size: 10px; font-weight: 600; color: #ff8f00; height: 22px;'"
                >
                  O Clube retornará uma cotação para a quantidade!
                </div>
                <div
                  v-else
                  style="height: 25px;"
                />
                <v-row class="mr-0">
                  <v-col
                    cols="6"
                    md="2"
                    class="pr-0"
                  >
                    <div :style="$vuetify.breakpoint.xs ? 'font-size: 10px; font-weight: 600; margin-top: 12px; text-align: right' : 'font-size: 12px; font-weight: 600; margin-top: 12px; text-align: right'">
                      Quantidade
                    </div>
                    <div
                      :style="$vuetify.breakpoint.xs ? 'font-size: 10px; text-align: right' : 'font-size: 12px; text-align: right'"
                      @click="Add_Quantidade(produto)"
                    >
                      {{ produto.quantidade }} unidades(s)
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="pr-0"
                  >
                    <div :style="$vuetify.breakpoint.xs ? 'font-size: 10px; font-weight: 600; margin-top: 12px; text-align: right' : 'font-size: 12px; font-weight: 600; margin-top: 12px; text-align: right'">
                      Prev. compra
                    </div>
                    <div
                      style="font-size: 12px; text-align: right;"
                      @click="Add_Quantidade(produto)"
                    >
                      {{ produto.quantidade }} dia(s)
                    </div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2"
                    cols="2"
                    class="pr-0"
                  >
                    <div style="font-size: 12px; font-weight: 600; text-align: right; margin-top: 12px;">
                      Valor Unitário
                    </div>
                    <div
                      style="font-size: 12px; text-align: right;"
                    >
                      {{ produto.valor_unitario > 0 ? 'R$ ' + currencyFormatter( produto.valor_unitario ) : 'R$ 0,00' }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2"
                    cols="2"
                    class="pr-0"
                  >
                    <div
                      style="font-size: 12px; font-weight: 600; text-align: right; margin-top: 12px;"
                    >
                      Subtotal
                    </div>
                    <div
                      style="font-size: 12px; text-align: right"
                    >
                      {{ produto.valor_subtotal > 0 ? 'R$ ' + currencyFormatter( produto.valor_subtotal ) : 'R$ 0,00' }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2"
                    cols="2"
                    class="pr-0"
                  >
                    <div
                      style="font-size: 12px; font-weight: 600; text-align: right; margin-top: 12px;"
                    >
                      Desconto
                    </div>
                    <div
                      style="font-size: 12px; text-align: right;"
                    >
                      {{ produto.valor_desconto > 0 ? '-R$ ' + currencyFormatter( produto.valor_desconto ) : 'R$ 0,00' }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2"
                    cols="2"
                    class="pr-0"
                  >
                    <div
                      style="font-size: 12px; color: #143693; font-weight: 600; margin-top: 12px; text-align: right;"
                    >
                      Valor Total
                    </div>
                    <div
                      style="font-size: 12px; font-weight: 600; text-align: right;"
                    >
                      {{ produto.valor_total > 0 ? 'R$ ' + currencyFormatter( produto.valor_total ) : 'R$ 0,00' }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2"
                :cols="$vuetify.breakpoint.smAndDown && produto.disponibilidade !== 2 ? '5' : '12'"
                class="d-flex flex-column align-end justify-end pt-1"
                style="text-align: right;"
              >
                <div v-if="produto.valor_unitario > 0">
                  <div style="font-size: 10px; font-weight: 600; margin-right: 4px;">
                    Valor Unitário
                  </div>
                  <div
                    style="font-size: 10px; margin-right: 4px;"
                  >
                    {{ produto.valor_unitario > 0 ? 'R$ ' + currencyFormatter( produto.valor_unitario ) : 'R$ 0,00' }}
                  </div>
                </div>
                <div v-if="produto.valor_desconto> 0">
                  <div
                    style="font-size: 10px; font-weight: 600; margin-right: 4px;"
                  >
                    Desconto
                  </div>
                  <div
                    style="font-size: 10px;margin-right: 4px;"
                  >
                    {{ produto.valor_desconto > 0 ? '-R$ ' + currencyFormatter( produto.valor_desconto ) : 'R$ 0,00' }}
                  </div>
                </div>
                <div v-if="produto.valor_unitario > 0">
                  <div
                    style="font-size: 10px; color: #143693; font-weight: 600; margin-right: 4px;"
                  >
                    Valor Total
                  </div>
                  <div
                    style="font-size: 10px; font-weight: 600; margin-right: 4px;"
                  >
                    {{ produto.valor_total > 0 ? 'R$ ' + currencyFormatter( produto.valor_total ) : 'R$ 0,00' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

  <script>
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagem: 'http://testelactpro.esteiogestao.com.br/produtos/24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png',
          }
        ),
      },
    },
    data () {
      return {
        descLength: 150,
        currencyFormatter,
      }
    },
    methods: {
    },
  }
  </script>
