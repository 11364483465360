<template>
  <base-loading v-if="!ready" />
  <div
    v-else
    class="content"
    style="padding: 20px"
  >
    <v-chip
      :color="statusPedidoColor(pedido.status)"
      label
      text-color="white"
      style="display: flex; justify-content: center;"
    >
      {{ pedido.status }}
    </v-chip>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="mt-4"
      >
        <v-card
          class="mt-0 pb-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title class="d-flex justify-space-between pb-0">
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Pedido
            </h3>
            <h3 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              #{{ pedido.pedido_id }}
            </h3>
            <v-btn
              color="#183B94"
              x-small
              class="text-none text-white"
              style="color: white; border-radius: 8px;"
              @click="generatePDF"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-printer
              </v-icon>
              Imprimir
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-space-between pb-0 pt-0">
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Data e hora:
            </h4>
            <h4 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              {{ moment(pedido.data_pedido).format('DD/MM/YYYY - HH:mm:ss') }}
            </h4>
          </v-card-title>
          <v-divider class="ml-4 mr-4 mt-2 mb-2" />
          <v-card-title class="d-flex justify-space-between pb-0 pt-0">
            <h4 style="font-size: 14px; font-weight: 700;">
              Dados do produtor
            </h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Produtor:</h4>
            <h4> {{ pedido.nome_produtor }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Fazenda:</h4>
            <h4>{{ pedido.nome_fazenda }}</h4>
          </v-card-title>
        </v-card>
        <v-card
          class="mt-0 pb-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Detalhes do pagamento
            </h2>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Subtotal dos produtos</h4>
            <h4>R$ {{ currencyFormatter(pedido.valor_subtotal) }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 12px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>Descontos</h4>
            <h4> R$ {{ currencyFormatter(pedido.valor_descontos) }}</h4>
          </v-card-title>
          <v-card-title
            style="font-size: 16px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0 mt-10"
          >
            <h4>Total</h4>
            <h4 style="text-align: right;">
              R$ {{ currencyFormatter( pedido.valor_total ) }}
            </h4>
          </v-card-title>
          <v-card-title
            style="font-size: 16px; font-weight: 700;"
            class="d-flex justify-space-between pb-0 pt-0"
          >
            <h4>
              Forma de pagamento
            </h4>
            <h4>
              {{ pedido.parcelas }} x de R$ {{ currencyFormatter(pedido.valor_total / pedido.parcelas) }}
            </h4>
          </v-card-title>
        </v-card>
        <v-card
          class="mt-0 pb-2 pl-2 pr-2"
          style="border-radius: 8px;"
        >
          <v-card-title class="pl-0">
            <h2 style="font-size: 14px; font-weight: 700; color: #183B94; ">
              Observação
            </h2>
          </v-card-title>
          <v-card-text
            class="pt-2"
            style="background-color: #E8EBF4; border-radius: 8px; height: 240px; font-size: 12px; overflow: auto;"
          >
            {{ pedido.observacao }}
          </v-card-text>
        </v-card>
        <v-btn
          v-if="$vuetify.breakpoint.lgAndUp && pedido.status === 'Aguardando Aceite Produtor'"
          color="success"
          block
          small
          height="35px"
          class="text-none text-white"
          style="border-radius: 8px;"
        >
          Aceitar este pedido
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.lgAndUp && pedido.status === 'Aguardando Aprovação'"
          color="red darken-3"
          block
          small
          height="35px"
          class="text-none text-white"
          style="border-radius: 8px; color: white;"
        >
          Solicitar cancelamento
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
        :class="$vuetify.breakpoint.lgAndUp ? 'py-0 mt-7 mb-16' : 'py-0 mt-0 mb-16'"
      >
        <v-card
          v-if="$vuetify.breakpoint.smAndUp"
          class="my-3 py-2 mt-0"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2
              style="font-size: 18px; font-weight: 700;"
            >
              Produtos
            </h2>
          </v-card-title>
          <div style="min-height: 687px; padding: 16px">
            <card-individual
              v-for="item in pedido.itens"
              :key="item.id"
              class="mb-5"
              :produto="item"
            />
          </div>
        </v-card>
        <div v-else>
          <div
            style="min-height: 100px;"
          >
            <card-individual
              v-for="item in pedido.itens"
              :key="item.id"
              class="mb-4"
              :produto="item"
            />
          </div>
        </div>
        <v-btn
          v-if="!$vuetify.breakpoint.lgAndUp && pedido.status === 'Aguardando Aceite Produtor'"
          color="success"
          block
          small
          height="35px"
          class="text-none text-white"
          style="border-radius: 8px;"
        >
          Aceitar este pedido
        </v-btn>
        <v-btn
          v-if="!$vuetify.breakpoint.lgAndUp && pedido.status === 'Aguardando Aprovação'"
          color="red darken-3"
          block
          small
          height="35px"
          class="text-none text-white"
          style="border-radius: 8px; color: white;"
        >
          Solicitar cancelamento
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { DADOS_PEDIDO } from '@/store/modules/pedidos'
  import { mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import { statusPedidoColor } from '../../utils/constants'
  import CardIndividual from './CardIndividual.vue'
  import JsPDF from 'jspdf'

  export default {
    components: {
      CardIndividual,
    },
    data () {
      return {
        currencyFormatter,
        statusPedidoColor,
        id: null,
        pdfUrl: null, // Inicialmente definido como null
      }
    },
    computed: {
      ...mapState('pedidos', ['ready', 'pedido']),
    },
    created () {
      const id = this.$route.query.id
      this.DADOS_PEDIDO(id)
    },
    methods: {
      ...mapActions('pedidos', [DADOS_PEDIDO]),
      generatePDF () {
        let y = 10
        const dateNow = (new Date()).toLocaleString()
        var totalPages = '{total_pages_count_string}'

        function addFooter (pageNumber, doc, totalPages) {
          const docWidth = doc.internal.pageSize.width
          const docHeight = doc.internal.pageSize.height

          doc.setFontSize(7)
          doc.text(dateNow, 10 - 5, docHeight - 10)
          doc.text('https://app.clubedoprodutor.com.br', docWidth / 2, docHeight - 10, { align: 'center' })
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - 10, { align: 'right' })
        }

        const doc = new JsPDF('portrait')

        doc.setFontSize(20)
        doc.setTextColor('#183B94')
        doc.setFont('helvetica', 'bold')
        doc.text(`Pedido   #${this.pedido.pedido_id}`, 10, y)
        doc.setFontSize(14)
        doc.text(`Data e hora: ${this.moment(this.pedido.data_pedido).format('DD/MM/YYYY - HH:mm:ss')}`, 10, y += 8)
        doc.text(`Status: ${this.pedido.status}`, 10, y += 8)
        doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', 140, 5, 60, 22)
        doc.setTextColor('black')

        // DADOS PRODUTOR
        doc.text('Dados do produtor', 10, y += 20)
        doc.line(10, y + 4, 200, y + 4)

        doc.autoTable({
          theme: 'plain',
          startY: y + 5,
          margin: { top: 43, left: 8, right: 10, bottom: 10 },
          body: [
            [{ content: 'Fazenda:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.pedido.nome_fazenda}`],
            [{ content: 'Produtor:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, `${this.pedido.nome_produtor}`],
          ],
          rowPageBreak: 'auto',
        })

        // DETALHES PAGAMENTO
        doc.text('Detalhes do pagamento', 10, y += 30)
        doc.line(10, y + 4, 200, y + 4)
        doc.autoTable({
          theme: 'plain',
          startY: y + 5,
          margin: { top: 43, left: 8, right: 10, bottom: 10 },
          body: [
            [{ content: 'Subtotal do produtos:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, { content: `R$ ${currencyFormatter(this.pedido.valor_subtotal)}`, styles: { halign: 'left', fontStyle: 'bold' } }],
            [{ content: 'Descontos:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, { content: `R$ ${currencyFormatter(this.pedido.valor_descontos)}`, styles: { halign: 'left', fontStyle: 'bold' } }],
            [{ content: 'Total:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, { content: `R$ ${currencyFormatter(this.pedido.valor_total)}`, styles: { halign: 'left', fontStyle: 'bold' } }],
            [{ content: 'Forma de pagamento:', styles: { halign: 'left', fontStyle: 'bold', cellWidth: 100 } }, { content: `${this.pedido.parcelas} x de R$ ${currencyFormatter(this.pedido.valor_total / this.pedido.parcelas)}`, styles: { halign: 'left', fontStyle: 'bold' } }],
          ],
          rowPageBreak: 'auto',
        })

        // observação
        doc.text('Observação', 10, y += 50)
        doc.line(10, y + 4, 200, y + 4)
        doc.autoTable({
          theme: 'plain',
          startY: y + 5,
          margin: { top: 43, left: 8, right: 10, bottom: 10 },
          body: [
            [{ content: `${this.pedido.observacao === null ? '' : this.pedido.observacao}`, styles: { halign: 'left' } }],
          ],
          rowPageBreak: 'auto',
        })

        // produtos
        doc.setFont('helvetica', 'bold')
        doc.text('Produtos', 10, y += 40)
        doc.line(10, y + 4, 200, y + 4)
        doc.setFont('helvetica', 'normal')
        doc.autoTable({
          theme: 'plain',
          startY: y + 5,
          rowPageBreak: 'auto',
          margin: { top: 10, left: 8, right: 10, bottom: 10 },
          columns: [
            { title: 'Produto', dataKey: 'nome' },
            { title: 'Marca', dataKey: 'marca' },
            { title: 'Qtd.', dataKey: 'quantidade' },
            { title: 'Valor Un.', dataKey: 'valor_unitario' },
            { title: 'Total', dataKey: 'valor_total' },
            { title: 'Desconto', dataKey: 'valor_desconto' },
            { title: 'Subtotal', dataKey: 'valor_subtotal' },
          ],
          body: this.pedido.itens,
          columnStyles: { valor_unitario: { minCellWidth: 30 }, valor_total: { minCellWidth: 30 }, valor_desconto: { minCellWidth: 30 }, valor_subtotal: { minCellWidth: 30 } },
          didParseCell: (data) => {
            const trocarPonto = data.column.dataKey === 'valor_unitario' || data.column.dataKey === 'valor_subtotal' || data.column.dataKey === 'valor_desconto' || data.column.dataKey === 'valor_total'
            if (trocarPonto) {
              data.cell.styles.halign = 'right'
              if (data.row.section === 'body') {
                if (data.cell.text[0] !== '') {
                  data.cell.text = `R$ ${currencyFormatter(data.cell.text)}`
                }
              }
            }
            if (data.column.dataKey === 'quantidade') {
              data.cell.styles.halign = 'right'
            }
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber, doc, totalPages)
          },
        })
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }

        doc.save('Pedido_' + this.pedido.pedido_id + '.pdf')
      },
    },
  }
</script>
